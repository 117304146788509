.container {
  position: absolute;
  top: 15%;
  left: 50%;
  width: 450px;
  margin-left: -225px;
  background: rgba(255, 255, 255, 1);

  :global {
    input {
      width: 279px;
      height: 20px;
      font-size: 14px;
      border: 1px solid rgba(151, 151, 151, 1);
      border-radius: 0;
    }

    textarea {
      width: 279px;
      height: 62px;
      padding: 2px;
      font-size: 14px;
      border: 1px solid rgba(151, 151, 151, 1);
      border-radius: 0;
    }

    button {
      width: 96px;
      height: 32px;
      margin-left: 80px;
      font-size: 14px;
      color: rgba(255, 255, 255, 1);
      background: rgba(34, 34, 34, 1);
      border-radius: 0;
    }
  }
}

.head {
  margin-bottom: 10px;
  font-size: 18px;
  color: #222;
}

.text {
  margin-bottom: 24px;
  font-size: 14px;
  color: #222;
}

.item {
  display: flex;
  align-items: center;
  height: 20px;
  margin-bottom: 20px;

  .itemLabel {
    width: 80px;
    font-size: 14px;
    color: #222;
  }

  .itemWrap {
    margin-top: 25px;
  }
}

.item_plus {
  display: flex;
  height: 95px;
  margin-top: 20px;
  margin-bottom: 20px;

  .itemLabel {
    width: 80px;
    font-size: 14px;
    color: #222;
  }
}

.item_avatar {
  display: flex;
  align-items: center;
  height: 20px;
  margin-top: 70px;
  margin-bottom: 70px;

  .label_avatar {
    width: 80px;
    font-size: 14px;
    color: #222;
  }

  .Wrap_avatar {
    margin-top: 10px;
  }
}

.input {
  width: 279px;
  height: 20px;
  border: 1px solid rgba(151, 151, 151, 1);
  border-radius: 0;
}

.input_max {
  width: 279px;
  height: 62px;
  border: 1px solid rgba(151, 151, 151, 1);
  border-radius: 0;
}

.avatar {
  display: flex;

  .img {
    margin-right: 10px;

    img {
      width: 160px;
      height: 100px;
    }
  }

  .text_avatar {
    display: flex;
    align-items: flex-end;

    .comment {
      font-size: 12px;
      font-weight: 400;
      line-height: 11px;
      color: rgba(136, 136, 136, 1);
    }
  }
}

.submit {
  width: 87px;
  height: 25px;
  margin-left: 60px;
  font-size: 14px;
  color: rgba(255, 255, 255, 1);
  background: rgba(34, 34, 34, 1);
  border-radius: 0;
}
