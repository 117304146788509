.logo {
  width: 112px;
  height: 32px;
}

.divider {
  margin: 0 19px;
}

.right {
  font-size: 12px;
}

.back {
  margin-right: 37px;
}
