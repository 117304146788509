:global {
  * {
    padding: 0;
    margin: 0;
  }

  body {
    background-color: #fff;

    :global {
      .ant-layout-header {
        display: flex;
        justify-content: space-between;
        color: #fff;
        background-color: #32373e;

        a {
          color: #fff;
        }
      }

      .ant-modal {
        width: 350px !important;
      }

      .ant-modal-content {
        border-radius: 0;

        .ant-modal-body {
          padding: 0;
        }
      }

      .ant-form-item-control {
        font-size: 14px;
        line-height: 12px;
      }

      .ant-form-explain {
        font-size: 14px;
      }
    }
  }
  /* stylelint-disable */
  @media screen and (max-width: 767px) {
    #root {
      position: relative;
      height: 100vh;
      letter-spacing: 0.5px;
      background: url(./statics/logo.png) center 30% no-repeat, linear-gradient(#666, #111);
      background-size: 224px;
      > * {
        display: none;
      }
      &::before,
      &::after {
        position: absolute;
        width: 100vw;
        text-align: center;
      }
      &::before {
        content: '本平台不支持移动端';
        top: 40vh;
        color: #fff;
        font-size: 24px;
        font-weight: 700;
      }
      &::after {
        content: '请使用 PC 端访问';
        top: 45vh;
        color: #cfcfcf;
        font-size: 16px;
      }
    }
  }
}
